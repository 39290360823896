<template>
  <validation-provider
    :default-errors="initialErrors"
    :default-values="initialValues"
    :resolver="resolver"
    instant-validate
    @submit="onSubmit"
  >
    <template #default="{ submitted, handleSubmit }">
      <form-layout
        ref="form"
        :class="$style.wrapper"
        :disabled="loading"
        method="post"
        @submit="handleSubmit"
      >
        <form-template>
          <template #controls>
            <fields-layout>
              <validation-field hidden name="_xsrf" />
              <validation-field hidden name="code" />

              <validation-field name="password" hide-errors :label="$i18n('password.caption')">
                <template #default="{ id, modelValue, onChange, name, invalid }">
                  <base-input
                    :id="id"
                    :value="modelValue"
                    :invalid="submitted && invalid"
                    :name="name"
                    data-qa="account-login-password"
                    type="password"
                    big
                    autocomplete="off"
                    @input-value="onChange"
                  />
                </template>
              </validation-field>
            </fields-layout>
          </template>

          <template #password-errors>
            <validation-errors all>
              <template #nude="{ errors }">
                <password-errors
                  :errors="errors"
                  :rules="passwordRules"
                  :dirty="submitted"
                  :max-repeat-password="maxRepeatPassword"
                />
              </template>
            </validation-errors>
          </template>

          <template #footer>
            <base-button
              size="l"
              color="black"
              type="submit"
              data-qa="account-login-submit"
              :loading="loading"
            >
              {{ $i18n('submit.button') }}
            </base-button>

            <div :class="$style.error">
              <validation-errors v-if="submitted" all>
                <template #nude="{ errors }">
                  <form-errors :errors="errors" :exclude="passwordRuleIds" />
                </template>
              </validation-errors>
            </div>
          </template>
        </form-template>
      </form-layout>
    </template>
  </validation-provider>
</template>

<script>
import { object, string } from 'yup';

import BaseButton from '@/components/button/button.vue';
import BaseInput from '@/components/input/input.vue';

import FieldsLayout from '@/shared/ui/fields-layout/fields-layout.vue';
import FormLayout from '@/shared/ui/form-layout/form-layout.vue';

import {
  ValidationErrors,
  ValidationField,
  ValidationProvider,
  yupResolver
} from '../../../../shared/ui/validate-form';
import FormErrors from '../../components/form-errors/form-errors.vue';
import FormTemplate from '../../components/form-template/form-template.vue';
import PasswordErrors from '../../components/password-errors/password-errors.vue';

export default {
  name: 'RestorePure',
  components: {
    FormErrors,
    FieldsLayout,
    FormLayout,
    ValidationProvider,
    BaseButton,
    ValidationField,
    ValidationErrors,
    BaseInput,
    FormTemplate,
    PasswordErrors
  },
  props: {
    maxRepeatPassword: { type: Number, default: 0 },
    passwordRules: {
      type: Array,
      required: true
    },
    initialErrors: {
      type: Object,
      default: () => ({})
    },
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    resolver() {
      let password = string().default('').required(this.$i18n('password.required'));
      for (const rule of this.passwordRules) {
        password = password.test({
          name: rule.id,
          test: (value) => new RegExp(`${rule.symbols}{${rule.minCount},}`).test(value)
        });
      }
      return yupResolver(
        object({
          password
        })
      );
    },
    passwordRuleIds() {
      return this.passwordRules.map(({ id }) => id);
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$refs.form.$el.submit();
    }
  }
};
</script>

<style module>
.wrapper {
  --title-font-weight: 500;
}

.error {
  margin-top: 15px;
}
</style>

<i18n lang="json">
{
  "password.caption": {
    "ru_RU": "Новый пароль",
    "en_US": "New Password"
  },
  "password.required": {
    "ru_RU": "Введите пароль",
    "en_US": "Enter password"
  },
  "submit.button": {
    "ru_RU": "Сохранить новый пароль",
    "en_US": "Save new password"
  }
}
</i18n>
