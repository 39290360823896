<template>
  <div :class="$style.wrapper">
    <div v-if="canShowForm" class="row">
      <div class="col-md-offset-2 col-md-8 col-xs-12">
        <div class="getting-started-content">
          <validation-provider
            :default-errors="initialErrors"
            :default-values="computedInitialValues"
            :resolver="resolver"
            instant-validate
            @submit="onSubmit"
          >
            <template #default="{ submitted, handleSubmit, values }">
              <form-layout ref="form" :disabled="loading" method="post" @submit="handleSubmit">
                <form-template>
                  <template #controls>
                    <fields-layout>
                      <validation-field hidden name="_xsrf" />

                      <validation-field name="email" :label="loginLabel" hide-errors>
                        <template #default="{ id, modelValue, onChange, name, invalid }">
                          <base-input
                            :id="id"
                            :value="modelValue"
                            :name="name"
                            data-qa="account-login-email"
                            :invalid="submitted && invalid"
                            type="text"
                            autocomplete="email"
                            autofocus
                            big
                            @input-value="onChange"
                          />
                        </template>
                      </validation-field>

                      <validation-field
                        name="password"
                        hide-errors
                        :label="$i18n('password.caption')"
                      >
                        <template #default="{ id, modelValue, onChange, name, invalid }">
                          <base-input
                            :id="id"
                            :value="modelValue"
                            :invalid="submitted && invalid"
                            :name="name"
                            data-qa="account-login-password"
                            type="password"
                            big
                            autocomplete="new-password"
                            @input-value="onChange"
                          />
                        </template>
                      </validation-field>
                    </fields-layout>
                  </template>

                  <template #password-errors>
                    <validation-errors v-if="!isLogin" all>
                      <template #nude="{ errors }">
                        <password-errors
                          :errors="errors"
                          :rules="passwordRules"
                          :dirty="submitted"
                        />
                      </template>
                    </validation-errors>
                  </template>

                  <template #footer>
                    <base-button
                      size="l"
                      type="submit"
                      color="black"
                      data-qa="account-login-submit"
                      :disabled="!values.email || !values.password"
                      :loading="loading"
                    >
                      <template v-if="isLogin">
                        {{ $trlMessage('account.login.enter') }}&nbsp;&rarr;
                      </template>
                      <template v-else>
                        {{ $trlMessage('account.login.button.register') }}
                      </template>
                    </base-button>

                    <div :class="$style.error">
                      <validation-errors v-if="submitted" all>
                        <template #nude="{ errors }">
                          <form-errors :errors="errors" :exclude="passwordRuleIds" />
                        </template>
                      </validation-errors>
                    </div>

                    <div v-if="!isLogin" :class="$style.linkContainer">
                      <a :class="$style.link" href="/account/login">
                        {{ $trlMessage('account.login.enter_to_huntflow') }}
                      </a>
                    </div>
                    <div v-else-if="needRememberButton" :class="$style.linkContainer">
                      <validation-field v-if="isRemember" hidden name="remember" />
                      <a
                        :class="$style.link"
                        href="/account/remember"
                        @click.prevent="handleRemember"
                      >
                        {{ $trlMessage('account.login.remember') }}
                      </a>
                    </div>
                  </template>
                </form-template>
              </form-layout>
            </template>
          </validation-provider>
        </div>
      </div>
    </div>
    <template v-if="canShowForm">
      <div class="row">
        <div class="col-md-offset-1 col-md-10 col-xs-12">
          <div
            class="getting-started-separator getting-started-separator_top30 getting-started-separator_bottom30"
          />
        </div>
      </div>
      <div v-if="authMethods.length" class="row">
        <div class="col-md-offset-2 col-md-3 col-xs-12">
          <div :class="$style.container">
            <div :class="$style.title">
              {{ socialBlockTitle }}
            </div>
            <social-buttons :methods="authMethods" />
          </div>
        </div>
      </div>
    </template>

    <div v-if="googleAuthMethods.length" class="row">
      <div class="col-md-offset-2 col-md-3 col-xs-12">
        <div :class="[$style.container, $style.top40]">
          <social-buttons :methods="googleAuthMethods" />
        </div>
      </div>
    </div>
    <div v-if="oauth2AuthMethods.length" class="row">
      <div class="col-md-offset-2 col-md-3 col-xs-12">
        <div :class="[$style.container, $style.top40]">
          <social-buttons :methods="oauth2AuthMethods" />
        </div>
      </div>
    </div>

    <div v-if="settingsAuth.saml" class="row">
      <div class="col-md-offset-2 col-md-8 col-xs-12">
        <div class="content content_mt_bigger">
          <link-button color="black" size="l" to="/account/saml/login">
            {{ $trlMessage('account.login.enter') }}&nbsp;&rarr;
          </link-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { object, string } from 'yup';

import BaseButton from '@/components/button/button.vue';
import LinkButton from '@/components/button/link-button.vue';
import SocialButtons from '@/components/hf/social-login/social-buttons.vue';
import BaseInput from '@/components/input/input.vue';

import FieldsLayout from '@/shared/ui/fields-layout/fields-layout.vue';
import FormLayout from '@/shared/ui/form-layout/form-layout.vue';

import {
  ValidationErrors,
  ValidationField,
  ValidationProvider,
  yupResolver
} from '../../../../shared/ui/validate-form';
import FormErrors from '../../components/form-errors/form-errors.vue';
import FormTemplate from '../../components/form-template/form-template.vue';
import PasswordErrors from '../../components/password-errors/password-errors.vue';

export default {
  name: 'LoginPure',
  components: {
    FormErrors,
    FieldsLayout,
    FormLayout,
    ValidationErrors,
    FormTemplate,
    ValidationField,
    ValidationProvider,
    BaseButton,
    LinkButton,
    BaseInput,
    PasswordErrors,
    SocialButtons
  },
  props: {
    settingsAuth: {
      type: Object,
      required: true
    },
    initialValues: {
      type: Object,
      required: true
    },
    initialErrors: {
      type: Object,
      default: () => ({})
    },
    passwordRules: {
      type: Array,
      required: true
    },
    methods: {
      type: Array,
      required: true
    },
    isLogin: Boolean
  },
  data() {
    return {
      loading: false,
      isRemember: false
    };
  },
  computed: {
    computedInitialValues() {
      return {
        ...this.initialValues,
        remember: true
      };
    },
    resolver() {
      let password = string().default('').required(this.$i18n('password.required'));
      if (!this.isLogin) {
        for (const rule of this.passwordRules) {
          password = password.test({
            name: rule.id,
            test: (value) => new RegExp(`${rule.symbols}{${rule.minCount},}`).test(value)
          });
        }
      }

      let email = string().default('').required(this.$i18n('email.required'));
      email = email.email();

      return yupResolver(
        object({
          email,
          password
        })
      );
    },
    passwordRuleIds() {
      return this.passwordRules.map(({ id }) => id);
    },
    needRememberButton() {
      return this.isLogin;
    },
    loginLabel() {
      return this.$i18n('email.caption');
    },
    canShowForm() {
      return !this.settingsAuth.google && !this.settingsAuth.oauth2 && !this.settingsAuth.saml;
    },
    authMethods() {
      return this.methods.filter((el) => el.available);
    },
    socialBlockTitle() {
      return this.isLogin
        ? this.$trlMessage('account.login.external.title')
        : this.$trlMessage('login.external.title.register');
    },
    googleAuthMethods() {
      if (!this.settingsAuth.google) {
        return [];
      }
      return this.methods.filter((el) => el.id === 'GOOGLE');
    },
    oauth2AuthMethods() {
      if (!this.settingsAuth.oauth2) {
        return [];
      }
      return this.methods.filter((el) => el.id === this.settingsAuth.oauth2.auth_type);
    }
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$refs.form.$el.submit();
    },
    async handleRemember() {
      this.isRemember = true;
      await this.$nextTick();
      this.onSubmit();
    }
  }
};
</script>

<style module>
.wrapper {
  --title-font-weight: 500;
}

.error {
  margin-top: 15px;
}

.link {
  padding: 0;
  border: none;
  font-size: 18px;
  line-height: 22px;
  color: $blueColor;
  text-decoration: none;
  background: none;

  &:hover {
    color: $linkHoverColor;
  }
}
.linkContainer {
  margin-top: 20px;
}
.container {
  margin-bottom: 100px;
}
.title {
  font-weight: 500;
  margin-bottom: 5px;
}
.top40 {
  margin-top: 40px;
}
</style>

<i18n lang="json">
{
  "login.caption": {
    "ru_RU": "Логин",
    "en_US": "Login"
  },
  "email.caption": {
    "ru_RU": "Адрес эл. почты",
    "en_US": "Email"
  },
  "password.caption": {
    "ru_RU": "Пароль",
    "en_US": "Password"
  },
  "login.required": {
    "ru_RU": "Введите логин",
    "en_US": "Enter login"
  },
  "email.required": {
    "ru_RU": "Введите адрес эл. почты",
    "en_US": "Enter email"
  },
  "password.required": {
    "ru_RU": "Введите пароль",
    "en_US": "Enter password"
  }
}
</i18n>
