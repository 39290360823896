<template>
  <div :class="[formClass, formStatus]" @click="handleClick">
    <social-button :key="method.id" :method="method" :oauth="oauth" :connecting="connecting">
      <template v-if="!oauth">
        <!-- eslint-disable-next-line vue/no-restricted-syntax -->
        {{ $trlMessage(method.name) }}
      </template>
    </social-button>

    <span v-if="!oauth" class="form-group__error" v-html="errorMessage" />
  </div>
</template>

<script>
import SocialButton from '@/components/hf/social-button/social-button.vue';

import integrationApi from '@/shared/api/integration';

export default {
  name: 'SocialButtonLogin',
  components: {
    SocialButton
  },
  props: {
    method: {
      type: Object,
      required: true
    },
    oauth: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      connecting: false,
      disconnecting: false
    };
  },
  computed: {
    formClass() {
      return this.oauth ? 'form-group__item' : 'form-group';
    },
    formStatus() {
      return this.disconnecting ? 'form-group_invalid' : null;
    },
    href() {
      return this.method.connect_settings.login_path;
    },
    socialHref() {
      return this.method.href ? this.method.href : '/';
    },
    socialPath() {
      return this.method.connect_settings.login_path;
    },
    buttonName() {
      return this.method.id.toLocaleLowerCase();
    },
    errorMessage() {
      return this.$trlMessage('login.error.employer', {
        authType: this.buttonName,
        a: (text) =>
          `<a href="https://superjob.ru" class="form-group__error-link" target="_blank">${text}</a>`
      });
    }
  },
  methods: {
    handleClick(e) {
      this.connecting = true;
      if (this.oauth) {
        return;
      }
      const promise = integrationApi
        .oauth2Connect({ path: this.socialPath, href: this.socialHref })
        .catch(() => {
          if (promise.canceled) {
            return;
          }
          this.disconnecting = true;
        })
        .finally(() => {
          this.connecting = false;
        });
      e.preventDefault();
    }
  }
};
</script>

<i18n lang="json">{}</i18n>
